<template>
    <dashboard-layout>
        <div class="content-area__body">
            <!-- job section -->
            <section class="resume-section section-gap">
                <div class="section-title-wrap">
                    <h4 class="section-title">{{ $t("Resume")}}</h4>
                    <div class="actions d-flex">
                        <label class="file-browse-button button primary-button">
                            <span class="text-capitalize">{{ $t("upload new resume")}}</span>
                            <input type="file"  ref="file" @change="attachFile()" accept="application/pdf" class="myfile" />
                        </label>
                        <submit-button
                                v-if="resumeUrl.length"
                                :loading="isLoading"
                                type="danger-button ml-2"
                                :click="removeResume"
                        >{{$t('Remove')}}</submit-button>
                    </div>
                </div>
                <h4 class="empty-message" v-if="resumeUrl.length === 0">
                    {{ $t("you have not uploaded your resume. please attach the file here. (maximum 5mb)")}}
                </h4>
                <div class="info-card" v-else>
                    <template>
                        <iframe v-if="isDoc" :src="`https://docs.google.com/viewer?url=${resumeUrl}&embedded=true`" class="img-fluid w-100 resume-iframe"></iframe>
                        <iframe v-if="!isDoc"  :src="resumeUrl" class="img-fluid w-100 resume-iframe"></iframe>
                    </template>
                </div>
            </section>
        </div>
    </dashboard-layout>
</template>

<script>
    import DashboardLayout from "../../layouts/DashboardLayout";
    import client from "../../app/api/Client";
    export default {
        data() {
            return {
                isLoading: false,
                resumeUrl: '',
                type: '',
                form: {
                    file :null
                }
            }
        },
        components: {
           DashboardLayout
        },
        computed: {
            isDoc(){
                return this.type == 'doc' || this.type == 'docx';
            },
        },
        methods : {
            getCandidateResume() {
                client().get('/candidate/resume').then(({data: {data}}) => {
                     this.resumeUrl = data.url;
                     this.type = data.ext;
                });
            },
            attachFile(){
                this.form.file = this.$refs.file.files[0]
                const formData = new FormData();
                formData.append('resume', this.form.file);
                client().post(`candidate/resume`,formData).then(({data : { data, message }})=> {
                    this.$toast.success(message);
                    const input = this.$refs.file;
                    input.type = 'text';
                    input.type = 'file';
                    this.form.file = null;
                    this.resumeUrl = data.url;
                    this.type = data.ext;
                }).catch(({response : { data ,status }}) => {
                    if(status === 422){
                        this.$toast.error(data.message.resume[0]);
                    }else{
                        this.$toast.error(data.message);

                    }
                });
            },

            async removeResume() {
                try {
                    let confirm = await this.$dialog.confirm({
                        title: this.$t(`Confirmation`),
                        body: this.$t(`Are you sure you want to remove the resume?`)
                    });

                    if (confirm) {
                        this.isLoading = true;
                        let {data: {message}} = await client().post(`candidate/resume`, {
                            _method: 'DELETE'
                        });
                        this.resumeUrl = '';
                        this.type = '';
                        this.$toast.success(message);
                    }
                } catch (e) {
                }
                this.isLoading = false;
            }
        },
        created() {
            this.getCandidateResume();
        }
    }
</script>
<style>
    .resume-iframe {
        height: 100vh;
    }
</style>
